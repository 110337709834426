import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AnalyticsService from '../services/AnalyticsService';
import logo from '../assets/images/logo_sindicato_metalurgicos_bauru.png';

function Home() {
    useEffect(() => {
        const params = {
            hitType: 'pageview',
            page: '/',
            title: 'Página inicial'
        }
        AnalyticsService.send(params);
    })

    return (
        <Container>
            <Row>
                <Col className="centralizar">
                    <img src={logo} alt="logo" width="30%"/>
                    <br />
                    Em construção
                </Col>
            </Row>
        </Container>
    );
}

export default Home;