import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom'
import AnalyticsService from './services/AnalyticsService'
import Home from './pages/Home'
import './App.css';

function App() {
  const routes = [
    {
      path: '/',
      component: <Home />
    }
  ]

  useEffect(() => {
    AnalyticsService.initialize();
  })
  
  return (
    <Router>
      <Routes>
        <Route element={<DefaultLayout />}>
          {routes.map((route) => (
            <Route key={route.path} path={route.path} element={route.component} />
          ))}
        </Route>
      </Routes>
    </Router>
  );
}

function DefaultLayout() {
  return (
    <>
      <main>
        <Outlet />
      </main>
    </>
  )
}

export default App;
